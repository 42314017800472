// libs
import { useState } from 'react';

// types
import { ILandingPageProps } from 'shared/components/AdPlatformWidgets/types';

/*
* @description returns
* pageFirstFoldData - widgets which will be rendered on SSR during doc request
* pageBelowTheFoldData - widgets which will get 
  rendered when the user scrolls the page to a point where the io element is visible
* showBelowTheFold - a boolean flag to show remaining widgets
* onVisible - this function will set showBelowFold which will render the remaining widgets
* eagerLoadingImageCount - no of images to be loaded eagerly
*/

const DEALS_PAGE_CSR_SSR_SPLIT = 5;
const EAGER_LOADING_COUNT = 5;

function useCsrSsrSplit(homepageData: ILandingPageProps['landingPageData']) {
  const [showBelowTheFold, setShowBelowTheFold] = useState(false);

  const pageFirstFoldData = homepageData?.slice(0, DEALS_PAGE_CSR_SSR_SPLIT) || [];
  const pageBelowTheFoldData = homepageData?.slice(DEALS_PAGE_CSR_SSR_SPLIT) || [];

  const onVisible = () => {
    setShowBelowTheFold(true);
  };

  return {
    pageFirstFoldData,
    pageBelowTheFoldData,
    onVisible,
    showBelowTheFold,
    eagerLoadingCount: EAGER_LOADING_COUNT,
  };
}

export default useCsrSsrSplit;
