// libs
import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';

// Components
import SomethingWentWrong from 'shared/components/SomethingWentWrong';
import PageNotFound from 'shared/components/NotFound';

interface IHomepageAPIProps {
  children: (data: { [key: string]: object[] | boolean | string }) => React.ReactNode;
  homepageData: object[];
  isFetching: boolean;
  isNotFound: boolean;
  isFetchingError: boolean;
  onIntlNetwork: boolean;
  placeholder: React.ReactNode;
  pageName: string;
  shouldTransformForDealsPlatform: boolean;
  errorStatusCode: string;
}

function HomePageAPI({
  children,
  homepageData,
  isFetching,
  isFetchingError,
  isNotFound,
  onIntlNetwork,
  placeholder,
  pageName,
  errorStatusCode,
}: IHomepageAPIProps) {
  if (isEmpty(homepageData)) {
    if (isFetching) {
      return <>{placeholder}</>;
    }

    if (isNotFound) {
      return <PageNotFound />;
    }

    if (isFetchingError) {
      return <SomethingWentWrong statusCode={errorStatusCode} />;
    }
  }

  return <>{children({ homepageData, onIntlNetwork, pageName })}</>;
}

export default memo(HomePageAPI);
