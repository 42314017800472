// libs
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

// helpers
import {
  Impression,
  IMPRESSION_TYPE,
  syncImpressionsHandler,
} from 'shared/helpers/utils/impressions';
import { getCustomerId } from 'shared/store/app/selectors';
import { asyncImpressionsHandler } from './helpers';
import useWishlistHandler from './useWishlistHandler';

// components
import PersonalizedWidgetRenderer from '@nykaa/deals-personalization';

// styles
import { themeDealsPlatform } from 'shared/styles/base';

// defs
import { ILandingPageProps } from 'shared/components/AdPlatformWidgets/types';

// constants
import { EAGER_IMAGE_LOADING_COUNT } from 'shared/constants';

// helpers
import { getDomainConfig } from 'shared/domainConfig/helpers';

interface ILandingPageChildProps {
  landingPageData: ILandingPageProps['landingPageData'];
  eagerLoadingCount?: number;
}

const Child = ({
  landingPageData,
  eagerLoadingCount = EAGER_IMAGE_LOADING_COUNT,
}: ILandingPageChildProps) => {
  const customerId = useSelector(getCustomerId);

  const asyncImpressionCallback = async (impressions: Impression[] = []) =>
    asyncImpressionsHandler(impressions, customerId);

  const syncImpressionCallback = (impressions: Impression[] = [], type: IMPRESSION_TYPE) =>
    syncImpressionsHandler(impressions, type);

  const { wishlistData, handleWishList } = useWishlistHandler();

  const { DN_STORE } = getDomainConfig();

  return (
    <>
      <PersonalizedWidgetRenderer
        widgetList={landingPageData}
        isServer={__SERVER__}
        isDesktop={false}
        isPersonalizationSupported={false}
        asyncImpressionCallback={asyncImpressionCallback}
        syncImpressionCallback={syncImpressionCallback}
        eagerLoadImages={eagerLoadingCount}
        eagerLoadInventories={eagerLoadingCount}
        theme={themeDealsPlatform}
        store={DN_STORE}
        cta={{
          wishlist: {
            handleWishList,
            wishlistData,
          },
        }}
      />
    </>
  );
};

export default memo(Child);
