// libs
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

// components
import PersonalizedWidgetRenderer from '@nykaa/deals-personalization';

// helpers
import {
  Impression,
  IMPRESSION_TYPE,
  syncImpressionsHandler,
} from 'shared/helpers/utils/impressions';
import useWishlistHandler from './useWishlistHandler';
import { asyncImpressionsHandler } from './helpers';

// selectors
import { getCustomerId } from 'shared/store/app/selectors';

// styles
import { themeDealsPlatform } from 'shared/styles/base';

// constants
import { EAGER_IMAGE_LOADING_COUNT } from 'shared/constants';

// defs
import { ILandingPageProps } from 'shared/components/AdPlatformWidgets/types';

// helpers
import { getDomainConfig } from 'shared/domainConfig/helpers';
interface DealsPlatformRender {
  landingPageData: ILandingPageProps['landingPageData'];
  eagerLoadingCount?: number;
}

function DealsPlatformRenderer({
  landingPageData,
  eagerLoadingCount = EAGER_IMAGE_LOADING_COUNT,
}: DealsPlatformRender) {
  const customerId = useSelector(getCustomerId);

  const asyncImpressionCallback = async (impressions: Impression[] = []) =>
    asyncImpressionsHandler(impressions, customerId);

  const syncImpressionCallback = (impressions: Impression[] = [], type: IMPRESSION_TYPE) =>
    syncImpressionsHandler(impressions, type);

  const { wishlistData, handleWishList } = useWishlistHandler();

  const { DN_STORE } = getDomainConfig();

  return (
    <PersonalizedWidgetRenderer
      widgetList={landingPageData}
      isServer={__SERVER__}
      isDesktop
      isPersonalizationSupported={false}
      asyncImpressionCallback={asyncImpressionCallback}
      syncImpressionCallback={syncImpressionCallback}
      eagerLoadImages={eagerLoadingCount}
      eagerLoadInventories={eagerLoadingCount}
      theme={themeDealsPlatform}
      store={DN_STORE}
      cta={{
        wishlist: {
          handleWishList,
          wishlistData,
        },
      }}
    />
  );
}

export default memo(DealsPlatformRenderer);
