export const QUERY_STRING = {
  PAGE_TYPE: 'pageType',
  PAGE_VARIATION: 'pageVariation',
};

export const PAGE_CONTEXT = {
  MWEB: '-mweb',
  DWEB: '-dweb',
};

export const DEFAULT_PREFIX_FOR_SLUG = 'nf-';
