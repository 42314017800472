//constants
import { WEB_APP_TYPE } from 'shared/constants';

//helpers
import { getWebAppType } from 'shared/helpers/deviceType/webAppType';
import {
  asyncImpressionsHandler as _asyncImpressionsHandler,
  Impression,
} from 'shared/helpers/utils/impressions';

export const asyncImpressionsHandler = async (impressions: Impression[], customerId: string) => {
  const deviceType =
    getWebAppType() !== WEB_APP_TYPE.DESKTOP ? WEB_APP_TYPE.MWEB : WEB_APP_TYPE.DESKTOP;
  _asyncImpressionsHandler(impressions, customerId, deviceType);
};
