export const WIDGET_TYPES = {
  CAROUSEL: 'CAROUSEL',
  SLIDING_WIDGET: 'SLIDING_WIDGET',
  COLUMN_GRID: 'COLUMN_GRID',
  DATA_WIDGET: 'DATA_WIDGET',
  TEXT_GRID: 'TEXT_GRID',
  IN_FOCUS: 'IN_FOCUS',
  SEO_DATA: 'SEO_DATA',
  RECOMMENDATION_WIDGET: 'RECOMMENDATION_WIDGET',
  BUTTON_GRID: 'BUTTON_GRID',
  FITCODE_PROFILE: 'FITCODE_PROFILE',
  STORY: 'STORY',
  SPLIT_BANNERS: 'SPLIT_BANNERS',
};

export const WIDGET_THEMES = {
  TOP_BANNER: 'TOP_BANNER',
  TOP_CATEGORIES: 'TOP_CATEGORIES',
  BRANDS_WE_LOVE: 'BRANDS_WE_LOVE',
  CURATED_COLLECTION: 'CURATED_COLLECTION',
  FANCY_WIDGET: 'FANCY_WIDGET',
  NEW_ABOARD_WIDGET: 'NEW_ABOARD_WIDGET',
  CONTENT_WIDGET: 'CONTENT_WIDGET',
  TRANSPARENT_BKG: 'TRANSPARENT_BKG',
  BUTTON_OUTLINE: 'BUTTON_OUTLINE',
  DEFAULT: 'default',
};

export const WIDGET_ITEM_TYPES = {
  BANNER: 'BANNER',
  TEXT_ONLY: 'TEXT_ONLY',
};

export const SSR_CSR_SPLIT = 3;

export const CAROUSEL = {
  URL_SOURCE_DESKTOP: 'Web',
  URL_SOURCE_MOBILE: 'Mobile',
  ASPECT_RATIO_SOURCE_DESKTOP: 'Web_aspect_ratio',
  ASPECT_RATIO_SOURCE_MOBILE: 'Mobile_aspect_ratio',
  DEFAULT_ASPECT_RATIO_DESKTOP: 4,
  DEFAULT_ASPECT_RATIO_MOBILE: 2,
};

export const PLACE_HOLDER_COLORS = {
  BEIGE: '#FFE9BD',
  LIGHT_AQUA: '#91CED6',
  LIGHT_BLUE: '#A6B8FF',
  LIGHT_CYAN: '#BAE4FF',
  LIGHT_PINK: '#FFA3B5',
};

export const ON_LOAD_PLACEHOLDER_COLOR = 'transparent';

export const BASE_WIDGET_PADDING_MOBILE = 10;
