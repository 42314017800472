// libs
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import isEmpty from 'lodash/isEmpty';

//Selectors
import { getSSRHeaders } from 'shared/store/app/selectors';
import {
  isFetching as _isFetching,
  isFetchingError as _isFetchingError,
  isNotFound as _isNotFound,
  getLandingPageData,
  getErrorStatusCode,
} from 'shared/store/landingpage/selectors';

// Redux Store
import { fetchHomepageData as _fetchHomepageData } from 'shared/store/landingpage/services';
import { updateStatusCode as _updateStatusCode } from 'shared/store/app/actions';

// def
import {
  IHomepageStateProps,
  IHomepageDispatchProps,
  IHomepageProps,
} from 'shared/layouts/Homepage/types';

// components
import HomepageAPI from './HomePageAPI';

const mapStateToProps = (state: any) => {
  return {
    homepageData: getLandingPageData(state),
    ssrHeaders: getSSRHeaders(state),
    isFetchingError: _isFetchingError(state),
    isFetching: _isFetching(state),
    isNotFound: _isNotFound(state),
    errorStatusCode: getErrorStatusCode(state),
  };
};

const mapDispatchToProps = {
  fetchHomepageData: _fetchHomepageData,
  updateStatusCode: _updateStatusCode,
};

const fetchData = async ({
  homepageData,
  fetchHomepageData,
  ssrHeaders,
  pageName,
  updateStatusCode,
  shouldTransformForDealsPlatform,
}: IHomepageProps) => {
  if (isEmpty(homepageData)) {
    const headers = __SERVER__ ? ssrHeaders : {};

    return fetchHomepageData(headers, pageName, shouldTransformForDealsPlatform).catch(
      (err: { status: number }) => {
        if (err.status === 404) {
          updateStatusCode(404);
        } else {
          updateStatusCode(500);
        }
      }
    );
  }
};

export default connect<IHomepageStateProps, IHomepageDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(frontloadConnect(fetchData)(HomepageAPI));
